<template>
  <ModalForm
    :title="title"
    class="modal--title-reset"
    text-btn-confirm="Добавить"
    @request="addDirtyWeightHandler"
    @reject="onReject"
  >
    <VInput
      label="Масса:"
      name="weight_dirty"
      class="mb-30"
      required
      :pattern="/^[0-9]\d*[.,]?(\d{0,3}?)$/"
      :custom-validation="validationDirtyWeightMinValue"
    >
      <Validation for="required">Поле обязательно</Validation>
      <Validation for="pattern">Только цифры с точностью до тысячных</Validation>
      <Validation for="customValidation">Общий вес не может быть меньше {{ consignment.weight_clear }}</Validation>
    </VInput>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import VInput from '@/components/Form/Vinput/VInput'
import Validation from '@/components/Form/Validation'

import { mixinFormData } from '@/mixins/form/mixinFormData'
import {
  mixinConsignmentValidationDirtyWeightMinValue
} from '@/mixins/form/validation/consignment/mixinConsignmentValidationDirtyWeightMinValue'

import { transformConsignmentWeightValue } from '@/utils/pages/Production/consignment/transform'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'

export default {
  name: 'ModalConsignmentWeight',
  components: {
    ModalForm,
    Validation,
    VInput
  },
  props: {
    consignment: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Добавить общий вес'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixinFormData, mixinConsignmentValidationDirtyWeightMinValue],
  methods: {
    async addDirtyWeightHandler (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        data.weight_dirty = transformConsignmentWeightValue(data.weight_dirty)

        const response = await ConsignmentController.updateWeightDirty(this.consignment.id, data)
        if (response?.status !== 200) return

        this.$emit('addDirtyWeight', response.data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },
    onReject () {
      this.$emit('reject')
    }
  }
}
</script>

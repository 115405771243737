<template>
  <div class="stage-card-list">
    <StageCard
      class="stage-card-list__item"
      v-for="(item, i) in stageList"
      :key="i"
      :stage="item"
    />
  </div>
</template>

<script>
import StageCard from '@/components/production/stage/StageCard'
export default {
  name: 'StageCardList',
  components: { StageCard },
  props: {
    stageList: {
      type: Array,
      required: true
    }
  }
}
</script>

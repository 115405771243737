<template>
  <ModalForm
    :title="title"
    class="modal--title-reset"
    text-btn-confirm="Продолжить"
    @request="utilizationHandler"
    @reject="onReject"
  >
    <VInput
      label="Количество:"
      name="count"
      class="mb-30"
      required
      :pattern="pattern.num"
    >
      <Validation for="required">Поле обязательно</Validation>
      <Validation for="pattern" key-text="num"/>
    </VInput>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import VInput from '@/components/Form/Vinput/VInput'
import Validation from '@/components/Form/Validation'
import { PATTERN_LIST } from '@/const/validation'
import { mixinFormData } from '@/mixins/form/mixinFormData'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'

export default {
  name: 'ModalConfirmationUtil',
  components: {
    ModalForm,
    Validation,
    VInput
  },
  props: {
    consignment: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Утилизация'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixinFormData],
  data: () => ({
    pattern: PATTERN_LIST
  }),
  methods: {
    async utilizationHandler (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        console.log('consignment', this.consignment)
        console.log('data', data)

        const response = await ConsignmentController.utilization(this.consignment.id, data)
        if (response?.status !== 200) return

        this.$emit('utilization', response.data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },
    onReject () {
      this.$emit('reject')
    }
  }
}
</script>

<template>
  <div>
    <div class="position position-info mb-20">
        <div class="position__item col-1">
            <InfoText
                label="Вид пружины"
                :text="position.shape ? position.shape.name : '-'"
            />
        </div>
    </div>

    <div class="position position-info">
        <!--1 Столбец-->
        <!--1 строка-->
        <div class="position__item col-1">
        <InfoText
            label="d проволоки"
            :text="position.d_wire"
        />

        <InfoText
            v-if="notType4"
            label="| Индекс"
            class="text-grey"
            :text="position.index"
        />

        <InfoText
            v-if="isType127 && isShape"
            label="| Индекс 2"
            class="text-grey"
            :text="position.index_2"
        />

        <InfoText
            v-if="visibleStiffness"
            label="| Жесткость, Н/мм"
            class="text-grey"
            :text="calcStiffness"
        />
        </div>

        <!--2 строка-->
        <div class="position__item col-1">
        <InfoText
            v-if="visibleDInner"
            label="D внутренний"
            :text="position.d_inner"
        />

        <InfoText
            v-if="visibleDOutput"
            :label="`D наружный${isType127 && isShape ? ' 1/2' : ''}`"
            :text="textDOuter"
        />

        <InfoText
            v-if="isType4"
            label="Габаритная длина изделия"
            :text="position.overall_length"
        />
        </div>

        <!--3 строка-->
        <div class="position__item col-1">
        <InfoText
            v-if="notType48"
            label="Длина тела (L0)"
            :text="position.body_length"
        />

        <InfoText
            v-if="isType8"
            label="Зазор"
            :text="position.gap"
        />
        </div>

        <!--4 строка-->
        <div class="position__item col-1">
        <InfoText
            v-if="isType17"
            label="Опорные витки 1/2"
            :text="textReferenceTurns"
        />

        <InfoText
            v-if="isType2"
            label="Длина по зацепам"
            :text="position.length_by_hooks"
        />

        <InfoText
            v-if="isType359"
            label="Число рабочих витков (n)"
            :text="position.number_turns"
        />

        <InfoText
            v-if="isType8"
            label="Число полных витков (n1)"
            :text="position.number_full_turns"
        />
        </div>

        <!--5 строка-->
        <div class="position__item col-1">
        <InfoText
            v-if="isType1"
            label="Число рабочих витков (n)/полных витков (n1)"
            :text="textNumberTurnsAndFullTurns"
        />

        <InfoText
            v-if="isType2"
            label="Тип зацепа 1"
            :text="position.hook_type_1 && position.hook_type_1.name"
        />

        <InfoText
            v-if="isType359"
            label="Длина отвода 1"
            :text="position.tap_length_1"
        />

        <InfoText
            v-if="isType7"
            label="Число витков на цилиндр"
            :text="position.number_turn_cylinder"
        />

        <InfoText
            v-if="isType8"
            label="Плоскостность"
            :text="position.flatness && position.flatness.name"
        />
        </div>

        <!--6 строка-->
        <div class="position__item col-1">
        <InfoText
            v-if="isType1"
            label="Шаг (t)"
            :text="position.step"
        />

        <InfoText
            v-if="isType2"
            label="Тип зацепа 2"
            :text="position.hook_type_2 && position.hook_type_2.name"
        />

        <InfoText
            v-if="isType359"
            label="Длина отвода 2"
            :text="position.tap_length_2"
        />

        <InfoText
            v-if="isType7"
            label="Число полных витков (n1)"
            :text="position.number_full_turns"
        />

        <InfoText
            v-if="isType8"
            label="Фаска"
            :text="position.chamfer && position.chamfer.name"
        />
        </div>

        <!--7 строка-->
        <div v-if="isType2 || isType5" class="position__item col-1">
        <InfoText
            v-if="isType2"
            label="Ориентация зацепов"
            :text="position.hook_orientation_1 && position.hook_orientation_1.name"
        />

        <InfoText
            v-if="isType5"
            label="Длина зацепа 1"
            :text="position.hook_length_1"
        />
        </div>

        <!--2 Столбец-->
        <!--1 строка-->
        <div class="position__item col-2 row-1">
        <InfoText
            v-if="notType48"
            :label="'Направление навивки' + labelWindingDir"
            :text="textWindingDir"
        />
        </div>

        <!--2 строка-->
        <div class="position__item col-2 row-2">
        <InfoText
            v-if="visibleDOutput"
            :label="`Допуск по D наруж.${isType127 && isShape ? ' 1/2' : ''}`"
            :text="textDOuterLimit"
        />

        <InfoText
            v-if="visibleDInner"
            label="Допуск по D внутр."
            :text="position.d_inner_limit"
        />

        <InfoText
            v-if="isType4"
            label="Допуск на линейные размеры"
            :text="position.linear_size_limit"
        />
        </div>

        <!--3 строка-->
        <div class="position__item col-2 row-3">
        <InfoText
            v-if="isType8"
            label="Загибы"
            :text="position.folds && position.folds.name"
        />

        <InfoText
            v-if="notType48"
            label="Допуск по длине"
            :text="position.body_length_limit"
        />
        </div>

        <!--4 строка-->
        <div class="position__item col-2 row-4">
        <InfoText
            v-if="isType2"
            label="Число рабочих витков (n)"
            :text="position.number_turns"
        />

        <InfoText
            v-if="isType35"
            label="Тип навивки"
            :text="position.winding_type && position.winding_type.name"
        />

        <InfoText
            v-if="visibleTurnsLimit"
            label="Допуск по виткам"
            :text="position.turns_limit"
        />
        </div>

        <!--5 строка-->
        <div class="position__item col-2 row-5">
        <InfoText
            v-if="isType2"
            label="Длина зацепа 1"
            :text="position.hook_length_1"
        />

        <InfoText
            v-if="isType359"
            label="Допуск на длину отвода 1"
            :text="position.tap_length_1_limit"
        />

        <InfoText
            v-if="isType7"
            label="Число витков на конус"
            :text="position.number_turn_cone"
        />
        </div>

        <!--6 строка-->
        <div class="position__item col-2 row-6">
        <InfoText
            v-if="isType2"
            label="Длина зацепа 2"
            :text="position.hook_length_2"
        />

        <InfoText
            v-if="isType359"
            label="Допуск на длину отвода 2"
            :text="position.tap_length_2_limit"
        />

        <InfoText
            v-if="isType7"
            label="Шаг (t)"
            :text="position.step"
        />

        <InfoText
            v-if="isType17"
            label="Допуск на шаг"
            :text="position.step_limit"
        />
        </div>

        <!--7 строка-->
        <div v-if="isType2 || isType7" class="position__item col-2 row-7">
        <InfoText
            v-if="isType2"
            label="Зазоры зацепа 1/2"
            :text="textHookGap"
        />

        <InfoText
            v-if="isType5"
            label="Угол загиба на зацепе"
            :text="position.hook_bend_angle"
        />
        </div>

        <!-- 3 столбец -->
        <!--1 строка-->
        <div class="position__item col-3 row-1">
        <InfoText
            label="Материал"
            :text="position.material && position.material.name"
        />
        </div>

        <!--2 строка-->
        <div class="position__item col-3 row-2">
        <InfoText
            label="Длина развертки/масса"
            :text="textSweepLengthAndWeight"
        />
        </div>

        <!--3 строка-->
        <div class="position__item col-3 row-3">
        <InfoText
            label="Твердость"
            :text="position.hardness"
        />
        </div>

        <!--4 строка-->
        <div class="position__item col-3 row-4">
        <InfoText
            label="Поверхностная обработка"
            :text="position.surface_treatment && position.surface_treatment.name"
        />
        </div>

        <!--5 строка-->
        <div class="position__item col-3 row-5">
        <InfoText
            label="Испытания"
            :text="position.tests && position.tests.name"
        />
        </div>

        <!--6 строка-->
        <div class="position__item col-3 row-6">
        <InfoText
            label="Покрытие/цвет"
            :text="textCoatingAndColor"
        />
        </div>
    </div>
  </div>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText'
import { mixinPositionType } from '@/mixins/views/Production/Position/mixinPositionType'
import { fixedNumberCopy } from '@/utils/number'
import { mixinPositionCalc } from '@/mixins/views/Production/Position/mixinPositionCalc'

export default {
  name: 'PositionInfo',
  components: { InfoText },
  mixins: [
    mixinPositionType,
    mixinPositionCalc
  ],
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  computed: {
    // 1 столбец
    // Диаметр наружный
    textDOuter () {
      return this.isType17 ? [this.position.d_outer_1, this.position.d_outer_2] : this.position.d_outer_1
    },
    // Опорные витки
    textReferenceTurns () {
      return [this.position.reference_turn_1?.name, this.position.reference_turn_2?.name]
    },
    // Число рабочих витков (n)/полных витков (n1)
    textNumberTurnsAndFullTurns () {
      return [this.position.number_turns, this.position.number_full_turns]
    },

    // 2 столбец
    // Направление навивки
    labelWindingDir () {
      if (this.isType35) return '/Угол м-у отводами'
      if (this.isType9) return '/Ориентация отводов'
      return ''
    },
    textWindingDir () {
      const array = [this.position.winding_dir?.name]
      if (this.isType35) {
        array.push(this.position.tap_angle)
      }
      if (this.isType9) {
        array.push(this.position.tap_orientation)
      }
      return array
    },
    // Допуск Диаметр наружный
    textDOuterLimit () {
      return this.isType17 ? [this.position.d_outer_1_limit, this.position.d_outer_2_limit] : this.position.d_outer_1_limit
    },
    // Зазоры зацепа
    textHookGap () {
      return [this.position.hook_gap_1, this.position.hook_gap_2]
    },

    // 3 столбец
    // Длина развертки/масса
    textSweepLengthAndWeight () {
      return [this.position.sweep_length, fixedNumberCopy(+this.position.weight, 5)]
    },
    // Покрытие/цвет
    textCoatingAndColor () {
      return [this.position.coating?.name, this.position.color?.name || this.position.color]
    },
    isShape () {
        if (!this.position.shape) return false
        else if (this.position.shape?.id === 1) return false
        else return true
    }
  }
}
</script>
